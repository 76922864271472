import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, VStack, Grid } from "@chakra-ui/react";
import Container from "src/components/Container";
import FormsBanner from "src/components/banners/FormsBanner";
import RaceForm from "../forms/EVF Riders Registration 2024 (final).pdf";
import StallForm from "../forms/E.V.F. Stalll EOI FORM 2023.pdf";
import EntertainmentForm from "../forms/E.V.F. ENTERTAINMENT APPLICATION 2022.pdf";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/forms/" } }) {
      frontmatter {
        banner_text
        before_form_grid
        show_entertainment_form
        show_race_form
        show_stallholder_form
      }
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  const showStall = data.show_stallholder_form;
  const showRace = data.show_race_form;
  const showEntertainment = data.show_entertainment_form;
  return (
    <Layout>
      <HeadSEO
        title="Forms | Evandale Village Fair"
        description="Download forms to enter races, become a stallholder, be an entertainer, or join the velocipede society."
        slug="/forms/"
      />
      <FormsBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="65% 40%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Heading as="h1" size="2xl">
              {page.banner_text}
            </Heading>
          </VStack>
        </Container>
      </FormsBanner>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.before_form_grid} />
          </VStack>
          <Grid
            mt="3rem"
            gridTemplateColumns={[null, null, "1fr 1fr"]}
            gap="2rem"
          >
            <Box
              as="a"
              href={RaceForm}
              gridColumn={[null, null, "span 2"]}
              className="filter square"
              display="grid"
              gridAutoRows="1fr"
              placeItems="center"
            >
              <Box
                gridColumn="1"
                gridRow="1"
                height="100%"
                position="relative"
                zIndex="1"
              >
                <StaticImage
                  src="../images/rider_wide.jpg"
                  alt="young boy riding penny farthing bicycle"
                  width={768}
                />
              </Box>
              <Box
                gridColumn="1"
                gridRow="1"
                w="100%"
                h="100%"
                background="rgba(0,0,0,0.5)"
                position="relative"
                zIndex="2"
              />
              <Heading
                gridColumn="1"
                gridRow="1"
                size="md"
                as="h3"
                color="white"
                position="relative"
                zIndex="2"
              >
                Race Entry Form
              </Heading>
            </Box>

            <Box
              as="a"
              href={StallForm}
              className="filter"
              display="grid"
              gridAutoRows="1fr"
              placeItems="center"
            >
              <Box gridColumn="1" gridRow="1" position="relative" zIndex="1">
                <StaticImage
                  src="../images/stallholder_square.jpg"
                  alt="woman selling plants at market stall"
                  width={670}
                  height={670}
                />
              </Box>
              <Box
                gridColumn="1"
                gridRow="1"
                w="100%"
                h="100%"
                background="rgba(0,0,0,0.5)"
                position="relative"
                zIndex="2"
              />
              <Heading
                gridColumn="1"
                gridRow="1"
                size="md"
                as="h3"
                color="white"
                position="relative"
                zIndex="2"
              >
                Stallholder Application Form
              </Heading>
            </Box>
            {showEntertainment && (
              <Box
                as="a"
                href={EntertainmentForm}
                className="filter"
                display="grid"
                gridAutoRows="1fr"
                placeItems="center"
              >
                <Box gridColumn="1" gridRow="1" position="relative" zIndex="1">
                  <StaticImage
                    src="../images/pianosquare.jpg"
                    alt="woman playing piano with man playing accordion"
                    width={670}
                    height={670}
                  />
                </Box>
                <Box
                  gridColumn="1"
                  gridRow="1"
                  w="100%"
                  h="100%"
                  background="rgba(0,0,0,0.5)"
                  position="relative"
                  zIndex="2"
                />
                <Heading
                  gridColumn="1"
                  gridRow="1"
                  size="md"
                  as="h3"
                  color="white"
                  position="relative"
                  zIndex="2"
                >
                  Entertainment Application Form
                </Heading>
              </Box>
            )}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default Contact;
